import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'
import { cx } from '@emotion/css'
import { Link, graphql, navigate } from 'gatsby'
import qs from 'qs'
import Img from 'gatsby-image'
import { Disclosure, Dialog, Transition } from '@headlessui/react'
import { ChevronRight, Filter, CheckSquare } from 'react-feather'
import axios from 'axios'
import {
	StyledForm,
	HomeButtonRow,
	HomeButton,
	FieldRow,
	FieldWrap,
	Required,
	StyledError,
} from '../form/styles'
import { Formik, Field } from 'formik'
import assign from 'lodash.assign'
import { flat, validationSwitch, validationSchema } from '../form/utils'
import FormFieldGroup from '../form/FormFieldGroup'
import FormFieldSingle from '../form/FormFieldSingle'
import PopUp from '../modal/forms'
import { Lottie } from '@crello/react-lottie'
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox'
import * as animationCheckbox from '../../animations/checkbox.json'

import AddressInput from '../dealer-search/address-input'

const sliderStyles = css`
	-webkit-appearance: none;
	${tw`bg-gray-400 h-1 outline-none rounded-full w-48`}
	&:-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
	}
	&:-moz-range-thumb,
	&:-webkit-slider-thumb {
		${tw`bg-blue-100 rounded-full cursor-pointer h-5 w-5`}
	}
`
const Button = tw.button`rounded px-6 py-2 text-dolphin-gray font-sans font-semibold text-base bg-yellow-500 cursor-pointer`
const CheckboxContainer = styled(CustomCheckboxContainer)`
	${tw`flex items-center justify-center`}
	input {
		${tw`border-0 p-0 h-px w-px -m-px absolute overflow-hidden whitespace-nowrap `}
		clip: rect(0 0 0 0);
		clippath: inset(50%);
	}
`

const PostcodeInput = ({ postcode, setPostcode }) => (
	<input
		type="text"
		id="postcode"
		name="postcode"
		value={postcode}
		onChange={event => setPostcode(event.target.value)}
		tw="bg-gray-400 border border-gray-100 rounded px-2 py-1 w-24"
		pattern="[0-9]{4}"
	/>
)
const GetAQuote = ({
	formData,
	systemImage,
	cleanerImage,
	mineralsImage,
	phControlImage,
	queryAddress,
	onQueryAddress,
	addresses,
	selectAddress,
	onSelectAddress,
	dealer,
	...rest
}) => {
	// const { postalCode, size } = props.location.search
	// 	? qs.parse(search, { ignoreQueryPrefix: true })
	// 	: { postalCode: '', size: 1 }
	const [postcode, setPostcode] = useState('')
	const [poolsize, setPoolsize] = useState(1)
	const [openQuote, setOpenQuote] = useState(false)
	return (
		<section
			id="get-a-quote"
			tw="relative flex flex-col w-full justify-center items-center text-center py-12 bg-gray-800 text-white font-display"
		>
			<h2 tw="text-3xl mb-8 font-display font-medium px-2 text-blue-500">
				WHICH OZONE SWIM SYSTEM IS RIGHT FOR ME?
			</h2>
			<h3 tw="font-sans text-lg mb-6 font-medium max-w-screen-md px-2">
				Define your pool’s location and size to find the most suitable Ozone
				Swim system for you.
			</h3>

			<form
				action=""
				onSubmit={e => {
					e.preventDefault()
					return setOpenQuote(true)
				}}
				tw="flex flex-col md:flex-row w-full md:items-center justify-center py-8 space-y-8 md:space-x-10 md:space-y-0"
				css={css`
					input#address-input {
						${tw`text-gray-800 font-semibold`}
					}
				`}
			>
				<div tw="flex items-center">
					<label htmlFor="address-input" tw="mx-8 md:mx-6">
						<span tw="text-blue-500 font-semibold">Step 1</span>.
					</label>

					<AddressInput
						{...{
							queryAddress,
							onQueryAddress,
							addresses,
							selectAddress,
							onSelectAddress,
							hideLabel: true,
							required: true,
							placeholder: 'postcode',
						}}
						tw="mb-auto w-auto"
					/>
				</div>
				<div tw="flex items-center">
					<label htmlFor="poolsize" tw="mx-8 md:mx-6">
						<span tw="text-blue-500 font-semibold">Step 2</span>. Select Pool
						Size
					</label>
					<div tw="relative">
						<input
							type="range"
							id="poolsize"
							name="poolsize"
							value={poolsize}
							onChange={event => {
								event.preventDefault()
								return setPoolsize(event.target.value)
							}}
							css={[
								sliderStyles,
								css`
									width: 175px;
								`,
							]}
							min="1"
							max="3"
						/>
						<div
							tw="absolute top-0 flex justify-between -mt-3.5"
							css={css`
								width: 175px;
							`}
						>
							<small>Small</small>
							<small>Medium</small>
							<small>Large</small>
						</div>
						<div
							tw="absolute bottom-0 flex justify-between -mb-5"
							css={css`
								width: 175px;
							`}
						>
							<small>50,000L</small>
							<small>85,000L</small>
							<small>110,000L</small>
						</div>
					</div>
				</div>
				<div tw="flex items-center">
					<label htmlFor="submit" tw="mx-8 md:mx-6">
						<span tw="text-blue-500 font-semibold">Step 3</span>.
					</label>
					<Button
						type="submit"
						id="submit"
						tw="bg-blue-500 -ml-2 font-sans font-normal"
					>
						Get a Quote
					</Button>
				</div>
			</form>
			<div tw="w-full">
				<div tw="flex flex-col md:flex-row w-full items-center justify-center text-center bg-gray-800 text-blue-500 font-display py-10 space-x-6">
					<h3 tw="font-semibold md:text-lg md:w-1/2 md:pr-4 md:text-right mb-2 md:mb-0">
						Recommended for your pool:
					</h3>
					<p tw="md:w-1/2 md:mr-auto md:pl-4 md:text-left text-gray-100">
						Dead Sea Minerals + Ozone Swim {poolsize}000
					</p>
				</div>
				<div tw="text-center text-lg w-full max-w-screen-xl px-2 mb-6 mx-auto">
					<div tw="flex flex-row flex-wrap w-full items-stretch">
						<div tw="flex flex-col text-center text-gray-50 w-1/2 md:w-1/4 px-2 space-y-1 my-4 -mt-4 h-full">
							<Link to={`/product/mineral-swim-minerals`}>
								<Img
									{...mineralsImage.nodes[0]}
									imgStyle={{
										width: 'auto',
										height: 'auto',
										top: 'unset',
										bottom: '5%',
										left: '50%',
										transform: 'translateX(-50%)',
										padding: '0 25%',
									}}
								/>
							</Link>
							<p tw="font-medium">
								<CheckSquare tw="h-5 w-5 inline-flex text-blue-500 font-sans font-normal" />{' '}
								Pool Minerals
							</p>
							<h3 tw="font-semibold text-gray-200 !mb-4">
								Mineral Swim Dead Sea Minerals
							</h3>
							<div tw="!mt-auto inline-flex justify-center">
								<Link
									tw="bg-yellow-500 rounded px-4 py-2 shadow-md text-gray-800"
									to={`/product/mineral-swim-minerals`}
								>
									Buy Now
								</Link>
							</div>
						</div>
						<div tw="flex flex-col text-center text-gray-50 w-1/2 md:w-1/4 px-2 space-y-1 mb-4 -mt-4 h-full">
							<Link to={`/product/ozone-swim-${poolsize}000`}>
								<Img
									{...systemImage.nodes[0]}
									imgStyle={{
										width: 'auto',
										height: 'auto',
										top: 'unset',
										bottom: '5%',
										padding: '0 5%',
									}}
								/>
							</Link>
							<p tw="font-medium">
								<CheckSquare tw="h-5 w-5 inline-flex text-blue-500" /> Pool
								System
							</p>
							<h3 tw="font-semibold text-gray-200 !mb-4">
								Ozone Swim {poolsize}000
								<br />
								<br tw="xl:hidden" />
							</h3>
							<div tw="!mt-auto inline-flex justify-center">
								<Link
									tw="bg-yellow-500 rounded px-4 py-2 shadow-md text-gray-800 font-sans font-normal"
									to={`/product/ozone-swim-${poolsize}000`}
								>
									Buy Now
								</Link>
							</div>
						</div>
						<div tw="flex w-full md:w-1/2 my-4 -mb-4">
							<div tw="w-full bg-gray-200 px-6 pt-6 rounded-lg">
								<p tw="font-medium text-gray-600 text-left text-base">
									Recommended optimisations
								</p>
								<div tw="flex flex-row flex-wrap w-full text-lg -mt-10 md:-mt-14">
									<div tw="text-center text-gray-800 w-1/2 px-2 space-y-1">
										<Link to={`/product/ph-drive`}>
											<Img
												{...phControlImage.nodes[0]}
												imgStyle={{
													width: 'auto',
													height: 'auto',
													top: 'unset',
													bottom: '5%',
													left: '50%',
													transform: 'translateX(-50%)',
													padding: '0 30%',
												}}
											/>
										</Link>
										<p tw="font-medium">Pool pH Control</p>
										<h3 tw="font-semibold text-gray-700">pH Drive i-Series</h3>
										<Link
											tw="inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md text-gray-800 !mt-4 font-sans font-normal"
											to={`/product/ph-drive`}
										>
											Buy Now
										</Link>
									</div>
									<div tw="text-center text-gray-800 w-1/2 px-2 space-y-1">
										<Link to={`/product/dolphin-m-600`}>
											<Img
												{...cleanerImage.nodes[0]}
												imgStyle={{
													width: 'auto',
													height: 'auto',
													top: 'unset',
													bottom: '5%',
													left: '50%',
													transform: 'translateX(-50%)',
													padding: '0 10%',
												}}
											/>
										</Link>
										<p tw="font-medium">Pool Cleaning</p>
										<h3 tw="font-semibold text-gray-700">Dolphin M600</h3>
										<Link
											tw="inline-flex bg-yellow-500 rounded px-4 py-2 shadow-md text-gray-800 !mt-4 font-sans font-normal"
											to={`/product/dolphin-m-600`}
										>
											Buy Now
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<QuoteModal
				{...{
					openQuote,
					setOpenQuote,
					formData,
					selectAddress,
					poolsize,
					dealer,
				}}
			/>
		</section>
	)
}
export default GetAQuote
const QuoteModal = ({
	openQuote,
	setOpenQuote,
	formData,
	poolsize,
	selectAddress,
	dealer,
}) => {
	const poolSize = size => {
		switch (size) {
			case 1:
				return 'Small (50,000L)'
			case 2:
				return 'Medium (85,000L)'
			case 3:
				return 'Large (110,000L)'
			default:
				return 'Small (50,000L)'
		}
	}
	const stateCode = state => {
		switch (state) {
			case 'Queensland':
				return 'QLD'
			case 'New South Wales':
				return 'NSW'
			case 'Victoria':
				return 'VIC'
			case 'South Australia':
				return 'SA'
			case 'Western Australia':
				return 'WA'
			case 'Northern Territory':
				return 'NT'
			case 'Tasmania':
				return 'TAS'
			case 'Australian Capital Territory':
				return 'ACT'
			default:
				return 'QLD'
		}
	}
	const [postcode, city, state, country] =
		(selectAddress &&
			selectAddress.name &&
			selectAddress.name.toString().split(', ')) ||
		''
	const [showPopUp, setShowPopUp] = React.useState(false)
	const openPopUp = () => setShowPopUp(true)
	const closePopUp = () => setShowPopUp(false)
	const {
		title: formTitle,
		description: formDescription,
		items: formItems,
	} = formData.edges[0].node

	const valuesMapped = flat(formItems).map(item => ({
		[item.inputName]:
			(item.inputName === 'postcode' && postcode) ||
			(item.inputName === 'poolsize' && poolSize(Number(poolsize))) ||
			'',
	}))
	valuesMapped.push({ readandagree: false })
	const validationMapped = flat(formItems).map(item => ({
		[item.inputName]: validationSwitch(item.inputName, item.validationText),
	}))
	validationMapped.push({ readandagree: validationSwitch('readandagree') })
	/** New Object from mapped Array */
	const valuesObject = assign({}, ...valuesMapped)
	const validationObject = assign({}, ...validationMapped)
	return (
		<>
			<Transition appear show={openQuote} as={Fragment}>
				<Dialog
					as="div"
					tw="fixed inset-0 z-10 overflow-y-auto"
					data-no-focus-lock
					open={openQuote}
					onClose={() => setOpenQuote(false)}
				>
					<div tw="min-h-screen px-4 text-center">
						<Transition.Child
							as={Fragment}
							enter={cx(tw`ease-out duration-300`)}
							enterFrom={cx(tw`opacity-0`)}
							enterTo={cx(tw`opacity-100`)}
							leave={cx(tw`ease-in duration-200`)}
							leaveFrom={cx(tw`opacity-100`)}
							leaveTo={cx(tw`opacity-0`)}
						>
							<Dialog.Overlay tw="fixed inset-0 bg-black opacity-30" />
						</Transition.Child>
						<span tw="inline-block h-screen align-middle" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter={cx(tw`ease-out duration-300`)}
							enterFrom={cx(tw`opacity-0 scale-95`)}
							enterTo={cx(tw`opacity-100 scale-100`)}
							leave={cx(tw`ease-in duration-200`)}
							leaveFrom={cx(tw`opacity-100 scale-100`)}
							leaveTo={cx(tw`opacity-0 scale-95`)}
						>
							<div tw="relative z-10 inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
								<Dialog.Title
									as="h3"
									tw="text-center text-xl font-display font-semibold leading-6 text-gray-900"
								>
									{formTitle}
								</Dialog.Title>
								<div tw="mt-2">
									<p tw="text-center text-sm text-gray-600 my-6">
										{formDescription}
									</p>
								</div>
								<Formik
									initialValues={valuesObject}
									validationSchema={validationSchema(validationObject)}
									onSubmit={async (values, { setSubmitting, resetForm }) => {
										const state_code = stateCode(state)
										setSubmitting(true)
										const urlZap =
											'https://hooks.zapier.com/hooks/catch/3520232/b6zxeno/'
										const urlTest =
											'https://test.salesforce.com/servlet/servlet.WebToLead'
										const urlSF =
											'https://webto.salesforce.com/servlet/servlet.WebToLead'
										const bodyZap = qs.stringify({
											...values,
											dealerName: dealer.name,
											dealerEmail: dealer.email.toString(),
											salesForceId: dealer.salesForceId,
											city,
											state,
											coordinates: selectAddress.coordinates,
											message: `Name: ${values.firstName} ${values.lastName}\nEmail: ${values.email}\nPhone: ${values.phone}\nPostcode: ${postcode}\nPoolsize: ${values.poolsize}\nLocal Dealer: ${dealer.name}\n\nMaytronics Ozone Pool Package\n\nRecommended for your pool > Dead Sea Minerals + Ozone Swim ${poolsize}000\n\nPool Minerals: Mineral Swim Dead Sea Minerals\nPool System: Ozone Swim ${poolsize}000\n\nRecommended optimisations\n\nPool pH Control: pH Drive i-Series\nPool Cleaning: Dolphin M 600\n\nYour comments:\n\n${values.message}`,
										})
										const bodySF = qs.stringify({
											oid: '00D58000000JkgU',
											recordType: '0124H000000QkWL', // B2C
											'00N4H00000EBmQp': 'MTAU', // subsidiary
											'00N4H00000EOB0W': 'Ozone Swim', // lead process
											lead_source: 'Website',
											retURL: 'https://',
											// debug: '1',
											// debugEmail: 'web.au@maytronics.com',
											company: `${values.firstName} ${values.lastName}`,
											first_name: values.firstName,
											last_name: values.lastName,
											email: values.email,
											mobile: values.phone,
											city,
											state_code,
											zip: values.postcode,
											country_code: 'AU',
											'00N4H00000EOB0H': dealer.salesForceId,
											'00N4H00000EOB0h': values.poolsize,
											description: `Maytronics Ozone Pool Package\n\nRecommended for your pool > Dead Sea Minerals + Ozone Swim ${poolsize}000\n\nPool Minerals: Mineral Swim Dead Sea Minerals\nPool System: Ozone Swim ${poolsize}000\n\nRecommended optimisations\n\nPool pH Control: pH Drive i-Series\nPool Cleaning: Dolphin M 600\n\nYour comments:\n\n${values.message}`, // package quote
											'00N4H00000Dvvku': values.updates, // opt-in knowledge
											'00N4H00000Dvvkp': values.updates, // opt-in promotions
										})
										const config = {
											headers: {
												mode: 'no-cors',
												'Content-Type': 'application/x-www-form-urlencoded',
												'Access-Control-Allow-Origin': '*',
											},
										}
										axios({
											url: urlSF,
											method: 'post',
											data: bodySF,
											// config,
										})
										resetForm() // clear form data
										setSubmitting(false) // submiting finished
										setOpenQuote(false)
										navigate('?form=sent') // track form sent
										return openPopUp() // user feedback
									}}
								>
									{({ isSubmitting, errors, touched, values }) => (
										<StyledForm>
											{formItems.map((item, index) => {
												if (item.items) {
													const { title, items } = item
													return (
														<FormFieldGroup
															key={title + index}
															// color="#fff"
															{...{ items, errors, touched, values }}
														/>
													)
												} else {
													return (
														<FormFieldSingle
															key={item.inputName + index}
															overrideWidth="full"
															// color="#fff"
															{...{
																item: { ...item, helpText: null },
																errors,
																touched,
																values,
															}}
														/>
													)
												}
											})}
											<FieldRow>
												<FieldWrap>
													<Field name="readandagree" type="checkbox">
														{({
															field,
															form: { setFieldValue, setTouched },
														}) => (
															<div tw="flex w-full mb-4 items-center">
																<CheckboxContainer
																	tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
																	onClick={() =>
																		setFieldValue(
																			'readandagree',
																			field.checked ? false : true
																		)
																	}
																	onKeyDown={() =>
																		setFieldValue(
																			'readandagree',
																			field.checked ? false : true
																		)
																	}
																>
																	<CustomCheckboxInput
																		{...field}
																		checked={field.checked}
																		name="readandagree"
																		type="checkbox"
																	/>
																	<Lottie
																		playingState={'playing'}
																		speed={1}
																		direction={field.checked ? 1 : -1}
																		width={24}
																		height={24}
																		tw="bg-white rounded"
																		className="lottie-container checkbox"
																		config={{
																			animationData: animationCheckbox.default,
																			loop: false,
																			autoplay: true,
																			renderer: 'svg',
																		}}
																		aria-hidden
																	/>
																</CheckboxContainer>
																<p tw="text-sm font-display">
																	I have read and I agree to{' '}
																	<a
																		tw="font-bold underline hover:no-underline"
																		href="/terms"
																		target="_blank"
																	>
																		Maytronics Terms &#38; Conditions
																	</a>{' '}
																	and{' '}
																	<a
																		tw="font-bold underline hover:no-underline"
																		href="/privacy"
																		target="_blank"
																	>
																		Privacy Policy
																	</a>{' '}
																	<Required>*</Required>
																</p>
															</div>
														)}
													</Field>
													{touched['readandagree'] && errors['readandagree'] ? (
														<StyledError>
															{`▲ ` + errors['readandagree']}
														</StyledError>
													) : (
														<></>
													)}
												</FieldWrap>
											</FieldRow>
											<FieldRow>
												<FieldWrap>
													<Field name="updates" type="checkbox">
														{({
															field,
															form: { setFieldValue, setTouched },
														}) => (
															<div tw="flex w-full mb-4 items-center">
																<CheckboxContainer
																	tw="w-6 h-6 cursor-pointer rounded-sm mr-2"
																	onClick={() =>
																		setFieldValue(
																			'updates',
																			field.checked ? false : true
																		)
																	}
																	onKeyDown={() =>
																		setFieldValue(
																			'updates',
																			field.checked ? false : true
																		)
																	}
																>
																	<CustomCheckboxInput
																		{...field}
																		checked={field.checked}
																		name="updates"
																		type="checkbox"
																	/>
																	<Lottie
																		playingState={'playing'}
																		speed={1}
																		direction={field.checked ? 1 : -1}
																		width={24}
																		height={24}
																		tw="bg-white rounded"
																		className="lottie-container checkbox"
																		config={{
																			animationData: animationCheckbox.default,
																			loop: false,
																			autoplay: true,
																			renderer: 'svg',
																		}}
																		aria-hidden
																	/>
																</CheckboxContainer>
																<p tw="text-sm font-display">
																	I wish to receive updates and promotions from
																	Maytronics.
																</p>
															</div>
														)}
													</Field>
												</FieldWrap>
											</FieldRow>
											<p tw="text-center text-sm text-gray-600 my-4">
												You will now be emailed your recommended pool system
												quote as well as your local Elite Pool Builder contact
												details.
											</p>
											<HomeButtonRow>
												<HomeButton type="submit" disabled={isSubmitting}>
													{isSubmitting ? 'Sending...' : 'Get your Quote'}
												</HomeButton>
											</HomeButtonRow>
										</StyledForm>
									)}
								</Formik>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition>
			<PopUp showDialog={showPopUp} close={closePopUp} />
		</>
	)
}
